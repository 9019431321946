import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
require("core-js/fn/array/find");
import { Link } from "gatsby";
import Seo1 from "../components/Seo/Seo1"

import Article from "../components/Article";
import Search from "../components/Search";
import { ThemeContext } from "../layouts";
import Seo from "../components/Seo";

import AlgoliaIcon from "!svg-react-loader!../images/svg-icons/search-by-algolia.svg?name=AlgoliaLogo";
class SearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      search_products: [],
      search_articles: [],
      products: [],
      articles: [],
    }
    this.render_search = this.render_search.bind(this)
  }

  componentDidMount() {
    let products = []
    this.props.data.allMarkdownRemark.edges.map((item, key) => {
      const product = item.node.frontmatter;
      products.push(product);
    });
    let articles = []
    this.props.data.supports.edges.map((item,key) => {
      const ca =  {
        name: item.node.frontmatter,
        route: item.node.fields.slug
      }
      articles.push(ca)
    })
    this.props.data.posts.edges.map((item,key) => {
      const ca =  {
        name: item.node.frontmatter,
        route: item.node.fields.slug
      }
      articles.push(ca)
    })
    this.setState({
      products: products,
      articles: articles
    })

    let param = this.props.location.search.split('?') 
    
    if (param.length < 2) return;
    if(param.length >= 2) {
      console.log('tags search=======')
      setTimeout(() => {
        this.setState({
          search: param[1].toLowerCase().replace(/%20/g, ' ')
        })
        let e = {
          target: {
            value: param[1].toLowerCase().replace(/%20/g, ' ')
          }
        }
        this.render_search(e)
      },50)
    }
  }

  render_search(e) {
    this.setState({
      search: e.target.value
    })

    let pr = [];
    let ar = [];
    if (e.target.value.length >= 1) {
      const se = e.target.value.toLowerCase();
      //search products
      this.state.products.map((product, key) => {
        if (product.name.toLowerCase().indexOf(se) !== -1 || product.category.toLowerCase().indexOf(se) !== -1) {
          //pr.push(product.name) change to title, but URL still linked to name
          let pri = {
            title: product.title,
            name: product.name
          }
          pr.push(pri)
        } else {
          let done = 0;
          if(product.keys !== null) product.keys.map((each_key, eindex) => {
            if (done === 0 && each_key.toLowerCase().indexOf(se) !== -1) {
              //pr.push(product.name) change to title, but URL still linked to name
              let pri = {
                title: product.title,
                name: product.name
              }
              pr.push(pri)
              done = 1;
            }
          })
        }
      })
      this.state.articles.map((article, key) => {
        if (article.name.title.toLowerCase().indexOf(se) !== -1) {
          let ari = {
            title: article.name.title,
            route: article.route
          }
          ar.push(ari)
        } else {
          let done = 0;
          if(article.name.keys !== null) article.name.keys.map((each_key, eindex) => {
            if (done === 0 && each_key.toLowerCase().indexOf(se) !== -1) {
                let ari = {
                  title: article.name.title,
                  route: article.route
                }
              ar.push(ari)
              done = 1
            }
          })
        }
      })
      this.setState({
        search_products: pr,
        search_articles: ar,
      })
    } else {
      this.setState({
        search_products: [],
        search_articles: [],
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Article theme={theme}>
              <div className="search_container">
                <div className="s01">
                  <form>
                    <div className="inner-form">
                      <div className="input-field first-wrap">
                        <input id="search" type="text" value = {this.state.search}
                          onChange = {(e) => this.render_search(e)}
                        />
                      </div>
                      <div className="input-field third-wrap">
                        <button className="btn-search" type="button">Search</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="Search_content">
                  <div className="products">
                    {
                      this.state.search_products.length === 0 ?
                      <div></div>
                      :
                      <div>
                        <h1>{this.state.search_products.length} Products</h1>
                        <ul className="search_list">
                          {
                            this.state.search_products.map((pro, index) => {
                              return(
                                <Link
                                  key={index}
                                  to={`/productdetail?${pro.name}`}
                                >
                                  <li >{pro.title}</li>
                                </Link>
                              )
                            })
                          }
                        </ul>
                      </div>
                    }
                  </div>
                  <div className="articles">
                    {
                      this.state.search_articles.length === 0 ?
                      <div></div>
                      :
                      <div>
                        <h1>{this.state.search_articles.length} Articles</h1>
                        <ul className="search_list">
                            {
                              this.state.search_articles.map((pro, index) => {
                                return(
                                  <Link
                                    key={index}
                                    to={`/${pro.route}`}
                                  >
                                    <li >{pro.title}</li>
                                  </Link>
                                )
                              })
                            }
                        </ul>
                      </div>
                    }
                  </div>
                </div>
                <Seo1
                  postTitle = {'Search at X2Lab Website.'}
                  postDescription = {'Gatsby Products list'}
                  keywords = {[]}
                />
              </div>
            </Article>
          )}
        </ThemeContext.Consumer>

        {/* --- STYLES --- */}
        <style jsx>{`
          .search_container {
            padding: 10px;
            max-width: 65em;
            margin: 0 auto !important;
            margin-bottom: 40px !important;
            .Search_content {
              .search_list {
                margin-left: 40px;
                li {
                  margin-bottom: 15px;
                }
              }
              h1 {
                margin: 0.5em 0 2em 0.3em;
                font-size: 1.2em;
                color: #0098e4;
                display: block;
              }
            }
          }
          .icon {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
          }
          .icon :global(svg) {
            height: 30px;
          }
        `}</style>
      </React.Fragment>
    );
  }
}

SearchPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default SearchPage;

/*eslint-disable */
export const searchQuery = graphql`
  query searchQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            keys
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    
    supports: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//support/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            keys
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "store-item" } }}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            name
            subtitle
            category
            price
            detail
            mainimage
            dimension
            rating
            keys
            subimage {
              image
            }
          }
        }
      }
    }
  }
`
